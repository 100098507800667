import { navigate as _navigate } from 'wouter/use-browser-location';

export const navigate: typeof _navigate = (to, options) => {
  return _navigate(to, options);
};

export const links = {
  view: {
    trail: {
      detailsByTrailId: (trailId: number | string) => `/view/trail/${trailId}`,
    },
  },
};
