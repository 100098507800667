import z from 'zod';
import { create } from 'zustand';
import { LatLngTuple, LayerGroup, layerGroup } from 'leaflet';
import { createSelectors } from '../../../utils/createSelectors.ts';
import { CreateTrailInput } from '../../../models/CreateTrailInput.ts';
import { NO_SEARCH_ELEMENT_INDEX } from '../../constants.ts';

export type CreateTrailFormModel = Pick<
  CreateTrailInput,
  'name' | 'difficultyLevel'
>;

const latLngSchema = z
  .array(z.number())
  .length(2)
  .transform((x) => x as LatLngTuple); // this "as" is a hack (should be "satisfies"), but not sure how to do [number, number] in zod
const pathPointsSchema = z
  .array(latLngSchema)
  .transform((x) => x satisfies PathPoints);
const pathPointsHistorySchema = z.object({
  past: z.array(pathPointsSchema),
  present: pathPointsSchema,
  future: z.array(pathPointsSchema),
});

const tryParseStringAsJSON = (s: string | null | undefined) => {
  try {
    switch (typeof s) {
      case 'string':
        return JSON.parse(s);
      case 'undefined':
        return undefined;
    }
    return null;
  } catch (_) {
    return null;
  }
};

export type PathPoints = LatLngTuple[];
class PathPointsHistory {
  protected static localStorageKey = 'PathPointsHistory_0';

  constructor(
    public readonly past: PathPoints[],
    public readonly present: PathPoints,
    public readonly future: PathPoints[],
  ) {}

  static createFromLocalStorage() {
    const value = localStorage.getItem(PathPointsHistory.localStorageKey);
    const object = tryParseStringAsJSON(value);
    if (object) {
      try {
        const parsed = pathPointsHistorySchema.parse(object);
        return new PathPointsHistory(
          parsed.past,
          parsed.present,
          parsed.future,
        );
      } catch (_) {
        /* ignore */
      }
    }
    return new PathPointsHistory([], [], []);
  }

  saveToLocalStorage() {
    localStorage.setItem(
      PathPointsHistory.localStorageKey,
      JSON.stringify({
        past: this.past,
        present: this.present,
        future: this.future,
      }),
    );
    return this;
  }

  push(next: PathPoints): PathPointsHistory {
    const past = [...this.past, this.present];
    const present = next;
    const future: PathPoints[] = [];

    return new PathPointsHistory(past, present, future);
  }

  undo(): PathPointsHistory {
    const past = this.past.filter((_, i, arr) => i < arr.length - 1);
    const present = this.past[this.past.length - 1];
    const future = [this.present, ...this.future];

    return new PathPointsHistory(past, present, future);
  }

  redo(): PathPointsHistory {
    const past = [...this.past, this.present];
    const present = this.future[0];
    const future = [...this.future.filter((_, i) => i !== 0)];

    return new PathPointsHistory(past, present, future);
  }

  toStatePartial(): Pick<
    EditorPathState,
    'pathPoints' | 'pathPointsHistory' | 'undoAvailable' | 'redoAvailable'
  > {
    return {
      pathPoints: [...this.present], // drop reference
      pathPointsHistory: this,
      undoAvailable: this.past.length > 0,
      redoAvailable: this.future.length > 0,
    };
  }
}

type EditorPathState = {
  mouseOverMarkerIndex: number;
  mouseOverMarkerIndexIn: (i: number) => void;
  mouseOverMarkerIndexOut: (i: number) => void;

  highlightMarkerIndex: number;
  handleHighlightMarkerIndex: (i: number) => void;

  pathPoints: PathPoints;
  undoAvailable: boolean;
  redoAvailable: boolean;
  pathPointsLayerGroup: LayerGroup;
  pathPointsHistory: PathPointsHistory;
  setPathPointsLayerGroup: (pathPointsLayerGroup: LayerGroup) => void;
  setPathPoints: (pathPoints: LatLngTuple[]) => void;
  undoPath: () => void;
  redoPath: () => void;
  addPointToPath: (pathPoint: LatLngTuple) => void;
  removePointFromPath: (pathPoint: LatLngTuple) => void;
  resetPath: () => void;
  formValue: CreateTrailFormModel;
  setFormValue: (formValue: CreateTrailFormModel) => void;
  formValid: boolean;
  setFormValid: (formValid: boolean) => void;
};

const store = create<EditorPathState>((set, get) => ({
  ...PathPointsHistory.createFromLocalStorage().toStatePartial(),
  mouseOverMarkerIndex: NO_SEARCH_ELEMENT_INDEX,
  mouseOverMarkerIndexIn: (i: number) => {
    set({ mouseOverMarkerIndex: i });
  },
  mouseOverMarkerIndexOut: (i: number) => {
    const state = get();
    if (state.mouseOverMarkerIndex === i) {
      set({ mouseOverMarkerIndex: NO_SEARCH_ELEMENT_INDEX });
    }
  },

  highlightMarkerIndex: NO_SEARCH_ELEMENT_INDEX,
  handleHighlightMarkerIndex: (i: number) => {
    const state = get();
    const nextState =
      state.highlightMarkerIndex === i ? NO_SEARCH_ELEMENT_INDEX : i; // bool ? clear : set
    return set({ highlightMarkerIndex: nextState });
  },

  pathPointsLayerGroup: layerGroup([]),
  setPathPointsLayerGroup: (pathPointsLayerGroup: LayerGroup) =>
    set({ pathPointsLayerGroup }),
  setPathPoints: (pathPoints: LatLngTuple[]) => {
    const state = get();
    const nextPathPointsHistory = state.pathPointsHistory.push(pathPoints);
    set({ ...nextPathPointsHistory.saveToLocalStorage().toStatePartial() });
  },
  addPointToPath: (pathPoint: LatLngTuple) => {
    const state = get();
    const nextPathPoints = [...state.pathPointsHistory.present, pathPoint];
    const nextPathPointsHistory = state.pathPointsHistory.push(nextPathPoints);
    set({ ...nextPathPointsHistory.saveToLocalStorage().toStatePartial() });
  },
  removePointFromPath: (pathPoint: LatLngTuple) => {
    const state = get();
    const nextPathPoints = state.pathPoints.filter(
      (point) => point !== pathPoint,
    );
    const nextPathPointsHistory = state.pathPointsHistory.push(nextPathPoints);
    set({ ...nextPathPointsHistory.saveToLocalStorage().toStatePartial() });
  },
  resetPath: () => {
    const nextPathPointsHistory = new PathPointsHistory([], [], []);
    set({ ...nextPathPointsHistory.saveToLocalStorage().toStatePartial() });
  },
  undoPath: () => {
    const state = get();
    const nextPathPointsHistory = state.pathPointsHistory.undo();
    set({ ...nextPathPointsHistory.saveToLocalStorage().toStatePartial() });
  },
  redoPath: () => {
    const state = get();
    const nextPathPointsHistory = state.pathPointsHistory.redo();
    set({ ...nextPathPointsHistory.saveToLocalStorage().toStatePartial() });
  },
  formValue: { name: '', difficultyLevel: 0 },
  setFormValue: (formValue: CreateTrailFormModel) => set({ formValue }),
  formValid: false,
  setFormValid: (formValid: boolean) => set({ formValid }),
}));

export const useEditorPathStore = createSelectors(store);
