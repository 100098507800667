import { FC, useEffect, useState } from 'react';
import { Icon, marker } from 'leaflet';
import { useMap } from '../../hooks/useMap';
import { MapStoreContextUnion } from '../../shared/mapStoreProvider';
import { useGeolocationStore } from '../../store/geolocationStore';
import 'leaflet-rotatedmarker';

type GeolocationMarkerProps = {
  MapStoreContext: MapStoreContextUnion;
};

const icon = new Icon.Default();
icon.options.shadowSize = [0, 0];

export const GeolocationMarker: FC<GeolocationMarkerProps> = ({
  MapStoreContext,
}) => {
  const { map } = useMap(MapStoreContext);
  const [geolocationMarker, setGeoLocationMarker] = useState<L.Marker | null>(
    null,
  );
  const { geolocationCoordinates, setGeolocationCoordinates } =
    useGeolocationStore();

  useEffect(() => {
    if (map && geolocationCoordinates) {
      const { latitude, longitude, heading } = geolocationCoordinates;
      const newGeolocationMarker = marker([latitude, longitude], {
        // @ts-ignore-next-line the extension doesn't typescript well
        rotationAngle: heading ? ~~heading - 180 : 0,
        // @ts-ignore-next-line the extension doesn't typescript well
        rotationOrigin: '80% center',
        shadowPane: '',
        icon,
      });

      if (geolocationMarker) {
        map.removeLayer(geolocationMarker);
      }

      map.addLayer(newGeolocationMarker);
      setGeoLocationMarker(newGeolocationMarker);
    }
  }, [map, geolocationCoordinates]);

  useEffect(() => {
    console.log('Default - start');
    navigator.geolocation.getCurrentPosition(
      (e) => {
        console.log('Default', e.coords);
        console.log('Precision - start');
        setGeolocationCoordinates(e.coords);
        navigator.geolocation.getCurrentPosition(
          (e) => {
            console.log('Precision', e.coords);
            setGeolocationCoordinates(e.coords);
            console.log('Watch - start');
            navigator.geolocation.watchPosition(
              (e) => {
                console.log('Watch', e.coords);
                setGeolocationCoordinates(e.coords);
              },
              () => {
                console.log('Watch - fail');
              },
              { enableHighAccuracy: true, maximumAge: 500 },
            );
          },
          () => {
            console.log('Precision - fail');
          },
          { enableHighAccuracy: true, maximumAge: 500 },
        );
      },
      () => {
        console.log('Default - fail');
      },
      { maximumAge: 500 },
    );
    // navigator.geolocation.clearWatch(watchId);
  }, []);

  return null;
};
