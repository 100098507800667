import { FC, useEffect } from 'react';
import L from 'leaflet';
import { useEditorMapStore } from '../../store/trailsMapStore.ts';
import { useDrawPath } from '../../hooks/useDrawPath.ts';
import { mapEditorPolyline } from '../../shared/mapPolylines.ts';
import { useMap } from '../../hooks/useMap.ts';
import { TrailGeoDetails } from '../../models/TrailGeoDetails.ts';
import { useEditorPathStore } from './store/editorPathStore.ts';
import { EditorMapStoreContext } from '../../shared/mapStoreProvider.tsx';

type TrailEditorMapProps = {
  trailGeoDetails?: TrailGeoDetails;
  trailGeoDetailsIsPlaceholderData: boolean;
};

export const TrailEditorMap: FC<TrailEditorMapProps> = ({
  trailGeoDetails,
  trailGeoDetailsIsPlaceholderData,
}) => {
  const { mapContainer, map } = useMap(EditorMapStoreContext);
  const { drawPath, clearPath } = useDrawPath(
    mapEditorPolyline,
    EditorMapStoreContext,
  );
  const { pathPoints } = useEditorPathStore();
  const { currentPath } = useEditorMapStore();

  useEffect(() => {
    if (!pathPoints.length && map && currentPath) {
      map.removeLayer(currentPath);
    }
  }, [currentPath, map, pathPoints.length]);

  useEffect(() => {
    if (currentPath) {
      if (trailGeoDetailsIsPlaceholderData) {
        currentPath.setStyle({
          color: 'gray',
          weight: 4,
        });
        currentPath.redraw();
      }
    }
  }, [currentPath, trailGeoDetailsIsPlaceholderData]);

  useEffect(() => {
    if (trailGeoDetails?.coordinates) {
      const previousPathLength = currentPath?.getLatLngs().length;
      const coordinates = trailGeoDetails.coordinates;
      const newPathLength = L.polyline(coordinates).getLatLngs().length;
      if (newPathLength !== previousPathLength) {
        drawPath(coordinates, { useFitBounds: false });
      }
    } else {
      clearPath();
    }
  }, [
    currentPath,
    trailGeoDetails,
    drawPath,
    clearPath,
    trailGeoDetailsIsPlaceholderData,
  ]);

  return <div className="w-full h-full z-10" ref={mapContainer}></div>;
};
