import { useQuery } from '@tanstack/react-query';
import { TrailDraftInput } from '../../../models/TrailDraft.ts';
import { LatLngTuple } from 'leaflet';
import { TrailGeoDetails } from '../../../models/TrailGeoDetails.ts';

export const useTrailDraft = (coordinates: LatLngTuple[]) => {
  return useQuery({
    queryKey: ['trailDraft', coordinates],
    enabled: coordinates.length > 1,
    placeholderData: (prev) => prev,
    queryFn: async () => {
      const response = await fetch(`/hikeup/api/v1/trail/draft`, {
        method: 'POST',
        body: JSON.stringify({
          coordinates,
          elevation: true,
          instructions: false,
        } as TrailDraftInput),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data: TrailGeoDetails = await response.json();
      return data;
    },
  });
};
