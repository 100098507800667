import {
  type FC,
  type MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DifficultyLevel } from './DifficultyLevel.tsx';
import { TrailRating } from './TrailRating.tsx';
import { Button } from './Button.tsx';
import { useTranslation } from 'react-i18next';
import { useAddTrailToFavorites } from '../../hooks/favoriteTrails/useAddTrailToFavorites.ts';
import { useRemoveTrailFromFavorites } from '../../hooks/favoriteTrails/useRemoveTrailFromFavorites.ts';
import { useSuspenseFetchUserFavoriteTrails } from '../../hooks/favoriteTrails/useSuspenseFetchUserFavoriteTrails.ts';
import { Heading1 } from './Heading.tsx';
import { TrailPreviewDTO } from '../../__generated__/HikeupApi.ts';
import { DevModeContext } from '../debug/DevModeProvider.tsx';
import { useDeleteTrail } from '../../hooks/trail/useDeleteTrail.ts';
import { ConfirmDialog } from './ConfirmDialog.tsx';
import { preventPropagation } from '../../utils/misc.ts';
import { distanceInKm, durationInHours } from '../../utils/formatter.ts';
import classNames from 'classnames';
import { Link } from 'wouter';

type TrailBasicInfoProps = {
  trail: Required<TrailPreviewDTO>; // todo(@Danielduel): unhack require https://app.clickup.com/t/86bz78p79
  onShowOnMap: MouseEventHandler<HTMLButtonElement>;
  isFetching?: boolean;
};

export const TrailBasicInfo: FC<TrailBasicInfoProps> = ({
  trail,
  onShowOnMap,
  isFetching,
}) => {
  const isDevMode = useContext(DevModeContext);
  const { t } = useTranslation();
  const { data: userTrailData } = useSuspenseFetchUserFavoriteTrails();
  const { mutate: addToFavorites } = useAddTrailToFavorites();
  const { mutate: removeFromFavorites } = useRemoveTrailFromFavorites();
  const { mutate: deleteTrail } = useDeleteTrail();
  const [isFavorite, setIsFavorite] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    const userTrailIds = (userTrailData ?? []).map((trail) => trail.id);
    setIsFavorite(userTrailIds.includes(trail.id));
  }, [trail.id, userTrailData]);

  return (
    <>
      <div
        className={classNames('flex-1 transition-opacity', {
          'opacity-70': isFetching,
        })}
      >
        <Heading1>{trail.name}</Heading1>
        {isFavorite && (
          <div className="my-2 font-bold">({t('TRAIL.trailOnList')})</div>
        )}
        <div>{`${t('TRAIL.distance')}: ${distanceInKm(trail.distance)}`}</div>
        <div>
          {`${t('TRAIL_EDITOR.duration')}: ${durationInHours(trail.duration)}`}
        </div>
        <div>
          {t('TRAIL.difficultyLevel')}:&nbsp;
          <DifficultyLevel value={trail.difficultyLevel} readOnly />
        </div>
        <div className="mb-2">
          {t('TRAIL.rate')}:&nbsp;
          <TrailRating value={trail.rate ?? 0} readOnly />
        </div>
        <div
          onClick={preventPropagation}
          className="flex flex-col items-start *:mb-4"
        >
          <Link to={`/trail/${trail.id}`}>
            <Button>{t('TRAIL.viewDetails')}</Button>
          </Link>
          <Button onClick={onShowOnMap}>{t('TRAIL.showOnMap')}</Button>
          {!isFavorite && (
            <Button onClick={() => addToFavorites(trail.id)}>
              {t('TRAIL.addToFavorites')}
            </Button>
          )}
          {isFavorite && (
            <Button onClick={() => removeFromFavorites(trail.id)}>
              {t('TRAIL.removeFromFavorites')}
            </Button>
          )}
          {isDevMode && (
            <Button
              className="bg-red-600 hover:bg-red-500 uppercase"
              onClick={() => setConfirmationDialogOpen(true)}
            >
              {t('TRAIL.deleteTrail')}
            </Button>
          )}
          {confirmationDialogOpen}
        </div>
      </div>
      {confirmationDialogOpen && (
        <ConfirmDialog
          isOpen={confirmationDialogOpen}
          onConfirm={() => {
            deleteTrail(trail.id);
            setConfirmationDialogOpen(false);
          }}
          onReject={() => {
            setConfirmationDialogOpen(false);
          }}
          config={{
            message: t('TRAIL.deleteTrailConfirm'),
          }}
        />
      )}
    </>
  );
};
