import {
  type FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  PropsWithChildren,
} from 'react';
import classNames from 'classnames';
import { useTrailsMapStore } from '../../store/trailsMapStore.ts';
import { useLeftSideBarStore } from '../../store/leftSideBarStore.ts';
import { Content } from './content/Content.tsx';
import { TopMenu } from './menu/topMenu/TopMenu.tsx';
import { useResetErrorBoundaryRef } from '../errorBoundary/ErrorBoundary.tsx';
import { ExpandButton } from './ExpandButton.tsx';
import { useBrowserLocation } from 'wouter/use-browser-location';

export const LeftSideBar: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { map } = useTrailsMapStore();
  const { expanded, setExpanded } = useLeftSideBarStore();
  const [path] = useBrowserLocation();
  const contentErrorBoundaryRefState = useResetErrorBoundaryRef();

  useEffect(() => {
    contentErrorBoundaryRefState.resetErrorBoundaryRef.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useLayoutEffect(() => {
    const scopedContainer = containerRef.current;
    if (!scopedContainer) return;

    const invalidateMapSize = () => {
      map?.invalidateSize({
        animate: true,
        pan: false,
      });
    };
    scopedContainer.addEventListener('transitionend', invalidateMapSize);
    return () => {
      scopedContainer.removeEventListener('transitionend', invalidateMapSize);
    };
  }, [map, containerRef, expanded]);

  useLayoutEffect(() => {
    map?.invalidateSize({
      animate: true,
      pan: false,
    });
  }, [expanded, map]);

  const toggleExpanded = useCallback(
    () => setExpanded(!expanded),
    [setExpanded, expanded],
  );

  return (
    <div
      ref={containerRef}
      className={classNames('bg-neutral-100 z-10', {
        // mobile and small tablets = vertical layout
        ['h-[30rem] max-h-[30rem] w-full flex flex-row']: expanded,
        // try to preserve 2:3 ratio when below fullhd
        // to provide any meaningful content on the sidepanel - limit it's width to 30rem (480px)
        ['xl:h-full xl:max-h-full xl:flex xl:flex-col ' +
        'xl:min-w-[30rem] xl:w-[30rem] ' +
        '2xl:min-w-[40rem] 2xl:w-[40rem]']: expanded,
        'h-12 xl:h-full xl:min-w-0 xl:w-12': !expanded,
      })}
    >
      <div className="flex flex-col-reverse xl:flex-row w-full xl:h-full">
        <div className="h-12 min-h-12 w-full min-w-full xl:w-12 xl:min-w-12 xl:h-full xl:max-w-12 bg-neutral-200 flex flex-row xl:flex-col justify-center">
          <ExpandButton expanded={expanded} onToggle={toggleExpanded} />
          <TopMenu />
        </div>
        <div className="h-full xl:w-full overflow-y-scroll">
          {expanded && <Content {...contentErrorBoundaryRefState} />}
        </div>
      </div>
    </div>
  );
};

export const LeftSideBarLayout: FC<PropsWithChildren> = ({ children }) => (
  <div className="flex flex-1 flex-col-reverse xl:flex-row h-[calc(100dvh-64px)]">
    <LeftSideBar />
    <div className="flex-1 z-0">{children}</div>
  </div>
);
