import {
  FC,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import L from 'leaflet';
import { useTrailsMapStore } from '../../store/trailsMapStore.ts';
import { DevModeContext } from '../debug/DevModeProvider.tsx';
import 'leaflet.markercluster';
import { InfiniteLoader } from '../shared/InfiniteLoader.tsx';
import { useMap } from '../../hooks/useMap.ts';
import { TrailMapPins } from './TrailsMapPins.tsx';
import { TrailsMapStoreContext } from '../../shared/mapStoreProvider.tsx';
import { GeolocationMarker } from '../mapAddons/GeolocationMarker.tsx';

const useMapMarker = () => {
  const isDevMode = useContext(DevModeContext);
  const { map } = useTrailsMapStore();
  const [marker, setMarker] = useState<L.Marker | null>(null);

  const setMapMarker = useCallback(
    (mapEvent: L.LeafletMouseEvent) => {
      if (map && isDevMode) {
        if (marker) {
          map.removeLayer(marker);
        }
        const newMarker = L.marker(mapEvent.latlng).addTo(map);
        setMarker(newMarker);
        const { lat, lng } = mapEvent.latlng;
        newMarker
          .bindPopup(`Coordinates: ${lat.toFixed(6)}, ${lng.toFixed(6)}`)
          .openPopup();
      }
    },
    [isDevMode, map, marker],
  );

  useEffect(() => {
    map?.on('click', setMapMarker);
    return () => {
      map?.off('click', setMapMarker);
    };
  }, [map, setMapMarker]);

  return { setMapMarker, map } as const;
};

export const TrailsMapInner = () => {
  const { mapContainer } = useMap(TrailsMapStoreContext);
  useMapMarker();

  return (
    <>
      <div className="w-full h-full z-10" ref={mapContainer} />
    </>
  );
};

export const TrailsMap: FC = () => (
  <>
    <TrailsMapInner />
    <Suspense fallback={<InfiniteLoader />}>
      <TrailMapPins />
    </Suspense>
    <GeolocationMarker MapStoreContext={TrailsMapStoreContext} />
  </>
);
