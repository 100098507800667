import { useEffect, useState } from 'react';
import L from 'leaflet';
import { useSuspenseFetchUserTrails } from '../../hooks/favoriteTrails/useSuspenseFetchUserTrails';
import { useSuspenseFetchPins } from '../../hooks/trail/useSuspenseFetchPins';
import { useMap } from '../../hooks/useMap';
import { greenPin, orangePin } from '../../shared/mapMarkers';
import { links, navigate } from '../leftSideBar/routing';
import { TrailsMapStoreContext } from '../../shared/mapStoreProvider';

export const TrailMapPins = () => {
  const { map } = useMap(TrailsMapStoreContext);
  const { data: pinData } = useSuspenseFetchPins();
  const { data: userTrailData } = useSuspenseFetchUserTrails();
  const [currentMarkerClusterGroup, setCurrentMarkerClusterGroup] =
    useState<L.MarkerClusterGroup | null>(null);

  useEffect(() => {
    if (map && pinData?.length) {
      const markerClusterGroup = L.markerClusterGroup({
        showCoverageOnHover: false,
      });

      if (currentMarkerClusterGroup) {
        map.removeLayer(currentMarkerClusterGroup);
      }

      const userTrailIds = (userTrailData ?? []).map((trail) => trail.id);
      pinData.forEach((item) => {
        if (!item.coordinates) return;
        if (!item.id) return;

        const icon = userTrailIds.includes(item.id) ? orangePin : greenPin;
        L.marker(item.coordinates, { icon })
          .addTo(markerClusterGroup)
          .on('click', () => {
            navigate(links.view.trail.detailsByTrailId(item.id));
          });
      });
      setCurrentMarkerClusterGroup(markerClusterGroup);
      map.addLayer(markerClusterGroup);
    }
  }, [pinData, map, userTrailData]);

  return null;
};
