import { useCallback, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import L, { layerGroup } from 'leaflet';
import { MarkerPopup } from '../MarkerPopup.tsx';
import { useEditorMapStore } from '../../../store/trailsMapStore.ts';
import { useEditorPathStore } from '../store/editorPathStore.ts';
import { blackPin, greenPin, redPin } from '../../../shared/mapMarkers.ts';
import { useTranslation } from 'react-i18next';

type AddPointToPathT = ReturnType<typeof useEditorPathStore>['addPointToPath'];
const createPopupContent = (
  marker: L.Marker,
  map: L.Map,
  addPointToPath: AddPointToPathT,
  t: (_: string) => string,
): HTMLDivElement => {
  const popupContent = document.createElement('div');
  const root = createRoot(popupContent);
  root.render(
    <MarkerPopup
      message={t('TRAIL_EDITOR.addPoint')}
      onButtonClick={() => {
        const { lat, lng } = marker.getLatLng();
        addPointToPath([lat, lng]);
        map.removeLayer(marker);
      }}
    />,
  );
  return popupContent;
};

export const useMapMarkers = () => {
  const { map } = useEditorMapStore();
  const {
    pathPoints,
    setPathPoints,
    addPointToPath,
    mouseOverMarkerIndexIn,
    mouseOverMarkerIndexOut,
    handleHighlightMarkerIndex,
    pathPointsLayerGroup,
    setPathPointsLayerGroup,
  } = useEditorPathStore();
  const [marker, setMarker] = useState<L.Marker | null>(null);
  const { t } = useTranslation();

  const clearMapMarker = useCallback(() => {
    if (map) {
      if (marker) {
        map.removeLayer(marker);
      }
    }
  }, [marker, map]);

  const setMapMarker = useCallback(
    (mapEvent: L.LeafletMouseEvent) => {
      if (map) {
        clearMapMarker();

        if (!pathPoints.length) {
          // Don't ask for the first point
          const { lat, lng } = mapEvent.latlng;
          addPointToPath([lat, lng]);
          return;
        }

        const newMarker = L.marker(mapEvent.latlng, {
          draggable: true,
          riseOnHover: true,
        })
          .on('click', clearMapMarker)
          .on('moveend', (e) => {
            const marker = e.sourceTarget as L.Marker;
            marker
              .bindPopup(createPopupContent(marker, map, addPointToPath, t), {
                minWidth: 124,
              })
              .openPopup();
          })
          .addTo(map);
        setMarker(newMarker);
        newMarker
          .bindPopup(createPopupContent(newMarker, map, addPointToPath, t), {
            minWidth: 124,
          })
          .openPopup();
      }
    },
    [addPointToPath, map, marker, !pathPoints.length],
  );

  useEffect(() => {
    map?.on('click', setMapMarker);
    map?.on('move', clearMapMarker);
    return () => {
      map?.off('click', setMapMarker);
      map?.off('move', clearMapMarker);
    };
  }, [map, setMapMarker, clearMapMarker]);

  useEffect(() => {
    if (map) {
      const newLayerGroup = layerGroup([]);
      pathPoints.forEach((item, index) => {
        let icon = greenPin;
        if (index === 0) {
          icon = redPin;
        } else if (index === pathPoints.length - 1) {
          icon = blackPin;
        }
        L.marker(item, { icon, draggable: true, riseOnHover: true })
          .on('click', () => {
            handleHighlightMarkerIndex(index);
          })
          .on('mouseout', () => {
            mouseOverMarkerIndexOut(index);
          })
          .on('mouseover', () => {
            mouseOverMarkerIndexIn(index);
          })
          .on('moveend', (e) => {
            const newLatLng = (e.target as L.Marker).getLatLng();
            pathPoints[index] = [newLatLng.lat, newLatLng.lng];
            setPathPoints([...pathPoints]);
          })
          .addTo(newLayerGroup);
      });
      pathPointsLayerGroup.clearLayers();
      setPathPointsLayerGroup(newLayerGroup);
      newLayerGroup.addTo(map);
    }
  }, [map, pathPoints]);

  return { pathPoints } as const;
};
