import { useEffect } from 'react';
import type { LatLngTuple } from 'leaflet';
import { DrawPathOptions, useDrawPath } from '../useDrawPath';
import { trailPreviewPolyline } from '../../shared/mapPolylines';
import { HikeupApi } from '../../shared/hikeup-api';
import { TrailsMapStoreContext } from '../../shared/mapStoreProvider';

const _useTrailDrawPathBasedOnSearchParams_useEffect = async (
  trailId: number,
  drawPath: (path: LatLngTuple[], opts: DrawPathOptions) => void,
) => {
  const response = await HikeupApi.trailController.findById(trailId, {
    format: 'json',
  });

  const trail = response.data;
  if (trail.coordinates) {
    drawPath(trail.coordinates, { useFitBounds: false });
  }
};

const useTrailDrawPathBasedOnSearchParams_onDataInQuery = (trailId: number) => {
  const { drawPath } = useDrawPath(trailPreviewPolyline, TrailsMapStoreContext);
  useEffect(() => {
    _useTrailDrawPathBasedOnSearchParams_useEffect(trailId, drawPath);
  }, []);
};

export const create_useTrailDrawPathBasedOnSearchParams = () => {
  const queryParams = new URLSearchParams(location.search);
  const drawTrailId = queryParams.get('draw_trail_id');
  if (drawTrailId) {
    return () =>
      useTrailDrawPathBasedOnSearchParams_onDataInQuery(+drawTrailId);
  }
  return () => void 0;
};

export const useTrailDrawPathBasedOnSearchParams =
  create_useTrailDrawPathBasedOnSearchParams();
