import { Heading2 } from '../shared/Heading.tsx';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../shared/Button.tsx';
import classNames from 'classnames';
import { useEditorPathStore } from './store/editorPathStore.ts';

type EditorActionPathPointsButtonsProps = {
  onUndo: () => void;
  undoDisabled: boolean;
  onRedo: () => void;
  redoDisabled: boolean;
  onCloseLoop: () => void;
};

export const EditorActionPathPointsButtons: FC<
  EditorActionPathPointsButtonsProps
> = ({ onUndo, undoDisabled, onRedo, redoDisabled, onCloseLoop }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4 md:flex-row xl:flex-col">
      <div className="flex md:inline-flex xl:flex gap-4">
        <Button className="w-32" onClick={onUndo} disabled={undoDisabled}>
          {t('TRAIL_EDITOR.undo')}
        </Button>
        <Button className="w-32" onClick={onRedo} disabled={redoDisabled}>
          {t('TRAIL_EDITOR.redo')}
        </Button>
      </div>
      <div className="inline-flex gap-4">
        <Button className="w-32" onClick={onCloseLoop}>
          {t('TRAIL_EDITOR.closeLoop')}
        </Button>
      </div>
    </div>
  );
};

export const PathPointsList: FC = () => {
  const { t } = useTranslation();

  const {
    pathPoints,
    mouseOverMarkerIndex,
    highlightMarkerIndex,
    addPointToPath,
    undoPath,
    undoAvailable,
    redoPath,
    redoAvailable,
  } = useEditorPathStore();

  const closeLoop = useCallback(() => {
    const firstPoint = pathPoints[0];
    addPointToPath([...firstPoint]);
  }, [addPointToPath, pathPoints]);

  return (
    <div className="relative h-full flex flex-col bg-white py-3 px-4 box-border rounded-lg">
      <Heading2 className="block text-center">
        {t('TRAIL_EDITOR.pathPoints')}
      </Heading2>
      <div className="overflow-y-scroll h-full w-full p-2 box-border">
        {pathPoints.map((item, index) => {
          const lat = item[0].toFixed(6);
          const lon = item[1].toFixed(6);
          return (
            <div
              className={classNames(
                'box-border p-2 mb-4 border-solid border-2 border-gray-800 hover:ring-2',
                {
                  'ring-4':
                    mouseOverMarkerIndex === index ||
                    highlightMarkerIndex === index,
                },
              )}
              key={`${index}-${lat}-${lon}`}
            >
              {`${index + 1}. [${lat}, ${lon}]`}
            </div>
          );
        })}
      </div>
      <div className="pt-2 w-full text-right">
        <EditorActionPathPointsButtons
          onUndo={undoPath}
          undoDisabled={!undoAvailable}
          onRedo={redoPath}
          redoDisabled={!redoAvailable}
          onCloseLoop={closeLoop}
        />
      </div>
    </div>
  );
};
