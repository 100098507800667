import { TrailsMapStoreProvider } from '../../shared/mapStoreProvider';
import { TrailsMap } from '../trailMap/TrailsMap';
import { MapDebugInfo } from '../debug/MapDebugInfo';
import { LeftSideBarLayout } from '../leftSideBar/LeftSideBar';

export const TrailViewPage = () => {
  return (
    <LeftSideBarLayout>
      <TrailsMapStoreProvider>
        <TrailsMap />
      </TrailsMapStoreProvider>
      <MapDebugInfo />
    </LeftSideBarLayout>
  );
};
