import { FC } from 'react';
import { TrailDetails } from './TrailDetails';
import { useParams } from 'wouter';

type TrailDetailsPanelInnerProps = {
  id: number;
};

const TrailDetailsPanelInner: FC<TrailDetailsPanelInnerProps> = ({ id }) => {
  return <TrailDetails id={id} onShowPathClick={() => void 0} />;
};

export const TrailDetailsPanel: FC = () => {
  const params = useParams();

  if (typeof params.trailId !== 'string') {
    console.error(`Trail id is not a string!`);
    return null;
  }

  const id = Number(params.trailId);

  return <TrailDetailsPanelInner id={id} />;
};
