import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../shared/Button.tsx';
import { ConfirmDialog } from '../../shared/ConfirmDialog.tsx';

type EditorActionButtonsProps = {
  onReset: () => void;
  onSave: () => void;
};

export const EditorActionButtons: FC<EditorActionButtonsProps> = ({
  onReset,
  onSave,
}) => {
  const { t } = useTranslation();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <Button
            className="w-32"
            onClick={() => setConfirmationDialogOpen(true)}
          >
            {t('GENERAL.discard')}
          </Button>
          <Button className="w-32" onClick={onSave}>
            {t('GENERAL.save')}
          </Button>
        </div>
      </div>
      {confirmationDialogOpen && (
        <ConfirmDialog
          isOpen={confirmationDialogOpen}
          onConfirm={() => {
            onReset();
            setConfirmationDialogOpen(false);
          }}
          onReject={() => {
            setConfirmationDialogOpen(false);
          }}
          config={{
            message: t('TRAIL_EDITOR.deleteConfirm'),
          }}
        />
      )}
    </>
  );
};
