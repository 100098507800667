import { create } from 'zustand';
import { createSelectors } from '../utils/createSelectors.ts';

type GeolocationState = {
  enabled: boolean;
  geolocationCoordinates: GeolocationCoordinates | null;

  setGeolocationCoordinates: (
    geolocationCoordinates: GeolocationCoordinates,
  ) => void;
};

// const mockCoordinates = {
//   latitude: 53,
//   longitude: 14,
//   altitude: null,
//   accuracy: 20,
//   altitudeAccuracy: null,
//   heading: 90, // East?
//   speed: 0.008810080413393321,
// };

const createStore = () =>
  create<GeolocationState>((set) => ({
    enabled: false,
    geolocationCoordinates: null, // mockCoordinates,

    setGeolocationCoordinates: (
      geolocationCoordinates: GeolocationCoordinates,
    ) => set({ geolocationCoordinates }),
  }));

export const useGeolocationStore = createSelectors(createStore());
