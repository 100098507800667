import { type FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'wouter';
import { MenuButton } from '../shared/MenuButton';
import { DevModeContext } from '../../../debug/DevModeProvider.tsx';
import { Authenticated } from '../../../shared/UserAuthenticated.tsx';

const TopMenuRoot: FC = () => {
  const { t } = useTranslation();
  const isDevMode = useContext(DevModeContext);

  return (
    <>
      <MenuButton
        iconName="Recommend"
        path="/view/recommended"
        tooltipLabel={t('TRAIL.leftSideBar.recommended')}
      />
      {isDevMode ? (
        <MenuButton
          iconName="Map"
          path="/view/search-nominatim"
          tooltipLabel={t('TRAIL.leftSideBar.searchNominatim')}
        />
      ) : null}
      <Authenticated>
        <MenuButton
          iconName="Favorite"
          path="/view/favorite-trails"
          tooltipLabel={t('TRAIL.leftSideBar.favoriteTrails')}
        />
      </Authenticated>
      <MenuButton
        iconName="Search"
        path="/view/search-trails"
        tooltipLabel={t('TRAIL.leftSideBar.searchTrails')}
      />
    </>
  );
};

export const TopMenu: FC = () => {
  return (
    <div className="h-full xl:w-full flex xl:flex-col xl:relative">
      <Route path="*" component={TopMenuRoot} />
    </div>
  );
};
