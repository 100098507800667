import { createContext, PropsWithChildren } from 'react';
import {
  useEditorMapStore,
  useTrailsMapStore,
} from '../store/trailsMapStore.ts';

export const TrailsMapStoreContext = createContext(useTrailsMapStore);

export const TrailsMapStoreProvider = (props: PropsWithChildren) => {
  return (
    <TrailsMapStoreContext.Provider value={useTrailsMapStore}>
      {props.children}
    </TrailsMapStoreContext.Provider>
  );
};

export const EditorMapStoreContext = createContext(useEditorMapStore);

export const EditorMapStoreProvider = (props: PropsWithChildren) => {
  return (
    <EditorMapStoreContext.Provider value={useEditorMapStore}>
      {props.children}
    </EditorMapStoreContext.Provider>
  );
};

export type MapStoreContextUnion =
  | typeof TrailsMapStoreContext
  | typeof EditorMapStoreContext;
