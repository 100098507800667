import { MapDebugInfo } from '../debug/MapDebugInfo.tsx';
import { TrailEditorToolbar } from './toolbar/TrailEditorToolbar.tsx';
import { TrailEditorMap } from './TrailEditorMap.tsx';
import {
  EditorMapStoreContext,
  EditorMapStoreProvider,
} from '../../shared/mapStoreProvider.tsx';
import { useMapMarkers } from './hooks/useMapMarkers.tsx';
import { useTrailDraft } from './hooks/useTrailDraft.ts';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { useEditorMapStore } from '../../store/trailsMapStore.ts';
import { useLeftSideBarStore } from '../../store/leftSideBarStore.ts';
import { useBrowserLocation } from 'wouter/use-browser-location';
import { useResetErrorBoundaryRef } from '../errorBoundary/ErrorBoundary.tsx';
import classNames from 'classnames';
import { ExpandButton } from '../leftSideBar/ExpandButton.tsx';
import { Route } from 'wouter';
import { TrailGeoDetails } from '../../models/TrailGeoDetails.ts';
import { GeolocationMarker } from '../mapAddons/GeolocationMarker.tsx';

export const TopMenuRoot = () => null;

export const LeftSideBar: FC<{ trailGeoDetails?: TrailGeoDetails }> = ({
  trailGeoDetails,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { map } = useEditorMapStore();
  const { expanded, setExpanded } = useLeftSideBarStore();
  const [path] = useBrowserLocation();
  const contentErrorBoundaryRefState = useResetErrorBoundaryRef();

  useEffect(() => {
    contentErrorBoundaryRefState.resetErrorBoundaryRef.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useLayoutEffect(() => {
    const scopedContainer = containerRef.current;
    if (!scopedContainer) return;

    const invalidateMapSize = () => {
      map?.invalidateSize({
        animate: true,
        pan: false,
      });
    };
    scopedContainer.addEventListener('transitionend', invalidateMapSize);
    return () => {
      scopedContainer.removeEventListener('transitionend', invalidateMapSize);
    };
  }, [map, containerRef, expanded]);

  useLayoutEffect(() => {
    map?.invalidateSize({
      animate: true,
      pan: false,
    });
  }, [expanded, map]);

  const toggleExpanded = useCallback(
    () => setExpanded(!expanded),
    [setExpanded, expanded],
  );

  return (
    <div
      ref={containerRef}
      className={classNames('bg-neutral-100 z-10 max-w-full', {
        // mobile and small tablets = vertical layout
        ['h-[40dvh] max-h-[40dvh]']: expanded,
        ['md:h-[30rem] md:max-h-[30rem] w-full flex flex-row']: expanded,
        // try to preserve 2:3 ratio when below fullhd
        // to provide any meaningful content on the sidepanel - limit it's width to 30rem (480px)
        ['xl:h-full xl:max-h-full xl:flex xl:flex-col ' +
        'xl:min-w-[30rem] xl:w-[30rem] ' +
        '2xl:min-w-[40rem] 2xl:w-[40rem]']: expanded,
        'h-12 xl:h-full xl:min-w-0 xl:w-12': !expanded,
      })}
    >
      <div className="flex flex-col-reverse xl:flex-row w-full max-w-full xl:h-full">
        <div className="h-12 min-h-12 w-full min-w-full xl:w-12 xl:min-w-12 xl:h-full xl:max-w-12 bg-neutral-200 flex flex-row xl:flex-col justify-center">
          <ExpandButton expanded={expanded} onToggle={toggleExpanded} />
          <div className="h-full xl:w-full flex xl:flex-col xl:relative">
            <Route path="*" component={TopMenuRoot} />
          </div>
        </div>
        <div className="h-full xl:w-full max-w-full overflow-y-scroll">
          {expanded && (
            <TrailEditorToolbar
              trailGeoDetails={trailGeoDetails}
              {...contentErrorBoundaryRefState}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const LeftSideBarEditorLayout: FC<
  PropsWithChildren & { trailGeoDetails?: TrailGeoDetails }
> = ({ children, trailGeoDetails }) => (
  <div className="flex flex-1 flex-col-reverse xl:flex-row h-[calc(100dvh-64px)]">
    <LeftSideBar trailGeoDetails={trailGeoDetails} />
    <div className="flex-1 z-0">{children}</div>
  </div>
);

export const TrailEditorPage = () => {
  const { pathPoints } = useMapMarkers();
  const { data, isPlaceholderData } = useTrailDraft(pathPoints);

  return (
    <EditorMapStoreProvider>
      <div className="flex flex-1 max-h-min min-h-min">
        <LeftSideBarEditorLayout trailGeoDetails={data}>
          <TrailEditorMap
            trailGeoDetails={data}
            trailGeoDetailsIsPlaceholderData={isPlaceholderData}
          />
        </LeftSideBarEditorLayout>
      </div>
      <MapDebugInfo />
      <GeolocationMarker MapStoreContext={EditorMapStoreContext} />
    </EditorMapStoreProvider>
  );
};
