import { type FC } from 'react';
import { Icon } from '../shared/Icon.tsx';

type ExpandButtonProps = {
  expanded: boolean;
  onToggle: () => void;
};

export const ExpandButton: FC<ExpandButtonProps> = ({ expanded, onToggle }) => {
  const iconName = expanded ? 'ChevronLeft' : 'ChevronRight';
  return (
    <button
      className="h-12 min-h-12 w-12 min-w-12 xl:w-full hover:max-xl:border-b-4 xl:hover:border-l-4 border-black box-border"
      onClick={onToggle}
    >
      <Icon iconName={iconName} className="py-3" />
    </button>
  );
};
