import { type FC, useCallback } from 'react';
import { useRoute } from 'wouter';
import classNames from 'classnames';
import { useLeftSideBarStore } from '../../../../store/leftSideBarStore';
import { Icon, type IconName } from '../../../shared/Icon';
import { useBrowserLocation } from 'wouter/use-browser-location';

type MenuButtonProps = {
  iconName: IconName;
  path: string;
  tooltipLabel?: string;
};

export const MenuButton: FC<MenuButtonProps> = ({
  iconName,
  path,
  tooltipLabel,
}) => {
  const [match] = useRoute(path);
  const [, navigate] = useBrowserLocation();
  const { setExpanded, expanded } = useLeftSideBarStore();

  const handleClick = useCallback(() => {
    if (match && expanded) {
      // if the user clicks on the current item while the menu is expanded
      setExpanded(false);
      return;
    }
    navigate(path);
    setExpanded(true);
  }, [navigate, path, setExpanded, match, expanded]);

  return (
    <button
      onClick={handleClick}
      className={classNames(
        'h-12 min-h-12 w-12 min-w-12 xl:w-full hover:max-xl:border-b-4 xl:hover:border-l-4 box-border border-black p-3 flex items-center justify-center hint--right',
        {
          'border-neutral-700 bg-neutral-100 border-l-2': match,
          'hover:pl-2': match && expanded,
        },
      )}
      aria-label={tooltipLabel}
    >
      <Icon iconName={iconName} />
    </button>
  );
};
