import { Heading1 } from '../../shared/Heading.tsx';
import { useEditorPathStore } from '../store/editorPathStore.ts';
import { FC, useCallback } from 'react';
import { PathPointsList } from '../PathPointsList.tsx';
import { useCreateTrail } from '../hooks/useCreateTrail.ts';
import { CreateTrailForm } from '../CreateTrailForm.tsx';
import { useTranslation } from 'react-i18next';
import { EditorActionButtons } from './EditorActionButtons.tsx';
import { EditorTrailDetails } from './EditorTrailDetails.tsx';
import { TrailGeoDetails } from '../../../models/TrailGeoDetails.ts';

type TrailEditorToolbarProps = {
  trailGeoDetails?: TrailGeoDetails;
};
export const TrailEditorToolbar: FC<TrailEditorToolbarProps> = ({
  trailGeoDetails,
}) => {
  const { t } = useTranslation();
  const { resetPath, pathPoints, pathPointsLayerGroup, formValue, formValid } =
    useEditorPathStore();
  const { mutate } = useCreateTrail();

  const resetPathFn = useCallback(() => {
    resetPath();
    pathPointsLayerGroup.clearLayers();
  }, [pathPointsLayerGroup, resetPath]);

  const savePath = useCallback(() => {
    if (formValid && pathPoints.length > 1) {
      const { name, difficultyLevel } = formValue;
      mutate({
        name,
        difficultyLevel,
        position: pathPoints[0],
        coordinates: pathPoints,
      });
      resetPathFn();
    }
  }, [formValid, formValue, mutate, pathPoints, resetPathFn]);

  return (
    <div className="h-full w-full max-h-screen bg-neutral-100 p-2 xl:p-8 flex box-border max-w-full">
      <div className="flex relative flex-1 h-full flex-col overflow-y-scroll md:flex-row xl:flex-col gap-4 overflow-x-hidden box-content max-w-full">
        <Heading1 className="text-center hidden xl:block">
          {t('TRAIL_EDITOR.title')}
        </Heading1>
        {pathPoints.length ? (
          <>
            <div className="flex-1 h-full w-full box-content max-w-full">
              <PathPointsList />
            </div>
            <div>
              <CreateTrailForm />
              <EditorTrailDetails trailGeoDetails={trailGeoDetails} />
              <EditorActionButtons onReset={resetPathFn} onSave={savePath} />
            </div>
          </>
        ) : (
          <div className="text-center h-full w-full">
            {t('TRAIL_EDITOR.hint')}
          </div>
        )}
      </div>
    </div>
  );
};
