import { useTrailsMapStore } from '../../store/trailsMapStore.ts';
import { useCallback, useContext } from 'react';
import { DevModeContext } from './DevModeProvider.tsx';

export const MapDebugInfo = () => {
  const isDevMode = useContext(DevModeContext);
  const { coordinates, setMapCoordinates, zoom, setMapZoom } =
    useTrailsMapStore();

  const setMapPosition = useCallback(() => {
    setMapCoordinates([53.45, 14.55]);
    setMapZoom(10);
  }, [setMapCoordinates, setMapZoom]);

  if (!isDevMode) {
    return null;
  }

  return (
    <div className="fixed bottom-2 right-2 text-black z-20">
      <button
        className="border  border-black p-0.5 rounded"
        onClick={setMapPosition}
      >
        reset
      </button>
      <div>
        {coordinates[0]}, {coordinates[1]}
      </div>
      <div>{zoom}</div>
    </div>
  );
};
