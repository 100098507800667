import { FC, PropsWithChildren, createContext } from 'react';
import { useGeolocationStore } from '../store/geolocationStore.ts';

export const GeolocationStoreContext = createContext(useGeolocationStore);

export const GeolocationStoreProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <GeolocationStoreContext.Provider value={useGeolocationStore}>
      {children}
    </GeolocationStoreContext.Provider>
  );
};
