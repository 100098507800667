import { type FC } from 'react';
import { Redirect, Route, Switch } from 'wouter';
import { ListOfRecommendedTrails } from '../../ListOfRecommendedTrails';
import { MapSearch } from '../../MapSearch';
import { TrailsSearch } from '../../trailsSearch/TrailsSearch.tsx';
import { UserTrails } from '../../UserTrails';
import {
  ErrorBoundary,
  type WithUseResetErrorBoundaryRef,
} from '../../errorBoundary/ErrorBoundary';
import { TrailDetailsPanel } from '../../trailDetails/TrailDetailsPanel.tsx';

const Recommended: FC = () => (
  <Switch>
    <Route path="/" component={ListOfRecommendedTrails} />
    <Route path="/trail/:trailId" component={TrailDetailsPanel} />
  </Switch>
);

const Trail: FC = () => (
  <Switch>
    <Route path="/:trailId" component={TrailDetailsPanel} />
  </Switch>
);

export const Content: FC<WithUseResetErrorBoundaryRef> = ({
  resetErrorBoundaryRef,
}) => (
  <ErrorBoundary resetErrorBoundaryRef={resetErrorBoundaryRef}>
    <Switch>
      <Route path="/">
        <Redirect to="/recommended" />
      </Route>
      <Route path="/trail" nest component={Trail} />
      <Route path="/recommended" nest component={Recommended} />
      <Route path="/search-nominatim" component={MapSearch} />
      <Route path="/search-trails" component={TrailsSearch} />
      <Route path="/favorite-trails" component={UserTrails} />
    </Switch>
  </ErrorBoundary>
);
