import { useCallback } from 'react';
import { LatLngTuple } from 'leaflet';
import { navigate } from 'wouter/use-browser-location';
import { useDrawPath } from '../useDrawPath';
import { trailPreviewPolyline } from '../../shared/mapPolylines';
import { TrailsMapStoreContext } from '../../shared/mapStoreProvider';

type WithTrailId = { id: number };
type WithTrailCoordinates = { coordinates: LatLngTuple[] | null };

export const useTrailDrawPath = (
  trail?: WithTrailId & WithTrailCoordinates,
) => {
  const { drawPath } = useDrawPath(trailPreviewPolyline, TrailsMapStoreContext);
  const trailDrawPath = useCallback(
    (_trail?: WithTrailId & WithTrailCoordinates) => {
      const __trail = _trail ?? trail;
      if (__trail && __trail.coordinates) {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('draw_trail_id', `${__trail.id}`);
        navigate(`${location.pathname}?${queryParams}${location.hash}`);
        drawPath(__trail.coordinates, { useFitBounds: true });
      }
    },
    [drawPath, trail],
  );

  return trailDrawPath;
};
