import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClientProvider } from '@tanstack/react-query';
import { type FC, type PropsWithChildren } from 'react';
import { DevModeProvider } from './components/debug/DevModeProvider';

import { getKeycloak } from './shared/keycloak.ts';
import { getQueryClient } from './shared/react-query.ts';
import { InfiniteLoader } from './components/shared/InfiniteLoader.tsx';

import { GeolocationStoreProvider } from './shared/GeolocationStoreProvider.tsx';

export const Providers: FC<PropsWithChildren> = ({ children }) => {
  const keycloak = getKeycloak();
  const queryClient = getQueryClient();

  return (
    <ReactKeycloakProvider
      LoadingComponent={<InfiniteLoader />}
      authClient={keycloak}
      initOptions={{ enableLogging: true }}
    >
      <QueryClientProvider client={queryClient}>
        <DevModeProvider>
          <GeolocationStoreProvider>{children}</GeolocationStoreProvider>
        </DevModeProvider>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
};

export const AnonymousProviders: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = getQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <DevModeProvider>{children}</DevModeProvider>
    </QueryClientProvider>
  );
};
